const determineAbsoluteUrl = (req, setLocalhost) => {
  var protocol = 'https:'
  var host = req ? req.headers.host : window.location.host
  if (host && host.indexOf('localhost') > -1) {
    if (setLocalhost) host = setLocalhost
    protocol = 'http:'
  }

  return {
    protocol: protocol,
    host: host,
    origin: protocol + '//' + host
  }
}

module.exports = determineAbsoluteUrl
