import makeGraphqlRequest from './graphql-requestor'
import absoluteUrl from './absolute-url-determiner'

const retrieveData = async (context, query, args) => {
  const { origin } = absoluteUrl(context.req)
  const vars = { url: `${origin}${context.asPath}`, ...args }

  let data = null
  // If we are server side rendering, no point in going over HTTP, go directly to the graphql schema
  if (context.query.gqm) {
    const response = await context.query.gqm.query(query, vars)
    if (response && response.errors && response.errors.length) {
      const error = new Error('Error making graphql request')
      error.errors = response.errors
      throw error
    }
    data = response.data
  } else if (typeof window !== 'undefined') {
    data = await makeGraphqlRequest(query, vars)
  }
  return { data, vars }
}

export default retrieveData
